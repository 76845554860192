// recoil
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const userFields = atom({
  key: 'userFields',
  default: {} as any,
  effects_UNSTABLE: [persistAtom],
});

export const brandFields = atom({
  key: 'brandFields',
  default: {} as any,
  effects_UNSTABLE: [persistAtom],
});

export const atomBrandId = atom({
  key: 'atomBrandId',
  default: '' as any,
  effects_UNSTABLE: [persistAtom],
});
