import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotificationContainer from './container/NotificationContainer';
import MypageContainer from './container/MypageContainer';
import { RecoilRoot } from 'recoil';

const App = () => {
  return (
    <div className="App">
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route
              path="/notification"
              element={<NotificationContainer />}
            ></Route>
            <Route path="/mypage" element={<MypageContainer />}></Route>
            {/* <Route path="/product/:productId" element={<Product />}></Route> */}
            {/* 엘리먼트의 상단에 위치하는 라우트들의 규칙을 모두 확인하고, 일치하는 라우트가 없다면 이 라우트가 화면에 나타나게 됩니다. */}
            {/* <Route path="*" element={<NotFound />}></Route> */}
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
};

export default App;
