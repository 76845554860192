import axios from './defaultClient';

const nameSpace = '/v1/me';

export const getMe = async (args: any) => {
  return await axios.get(nameSpace + '/', {
    params: {},
  });
};

export const updateSetting = async (args: any) => {
  return await axios.patch(nameSpace, {
    agreePushNoti: args.agreePushNoti,
    agreeEventNoti: args.agreeEventNoti,
    agreePromotionNoti: args.agreePromotionNoti,
  });
};
