import axios from './defaultClient';

const nameSpace = '/v1/brand';

export const getBrandPost = async (args: any) => {
  return await axios.get(nameSpace, {
    params: {
      brandId: args.brandId,
    },
  });
};
