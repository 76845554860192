import React, { useEffect, useMemo, useState } from 'react';
import { BoxWrapper, Profile, Tab, Itmes, Footer } from './styled';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import { debounce } from 'lodash';
import LoadingRound from '../common/LoadingRound';

const NotifiedBox = ({
  items,
  itemsLoading,
  brand,
  me,
  list,
  unreadCount,
  doRedirect,
}: any) => {
  const styleForBg = useMemo(() => {
    if (brand?.appIcon)
      return {
        backgroundImage: `url(${brand.appIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '24px',
        height: '24px',
      };
    else
      return {
        width: '24px',
        height: '24px',
      };
  }, [brand]);

  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const handleScroll = () => {
    const { documentElement } = document;
    setIsScrollEnd(
      documentElement.scrollTop + documentElement.clientHeight >=
        documentElement.scrollHeight - 50
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll, 1));
    return () => window.removeEventListener('srcoll', handleScroll);
  });

  useEffect(() => {
    var AOS = require('aos');
    AOS.init();
  });
  return (
    <BoxWrapper>
      <div className="lillo-iframe-box-inner">
        <section
          className="lillo-iframe-bottom-mark"
          onClick={() => {
            window.open('https://lillo.co.kr');
          }}
        >
          <div
            className={'white-gradient' + (isScrollEnd ? ' disable' : '')}
          ></div>
          <div className="lillo-mark">
            <div className="img-box"></div>
            <span>릴로 알림함 이용중</span>
          </div>
        </section>
        <section className="lillo-noti-box-head">
          <div className="lillo-iframe-noti-box-top">
            <div className="main-title">
              <div className="main-icon-wrapper" style={styleForBg}></div>
              <span className="main-title-text">
                <span className="brand-name">{brand.brandName || ''}</span>
                &nbsp;알림함
              </span>
              <div
                className={
                  'main-item-count ' + (!unreadCount ? 'all-read' : '')
                }
              >
                {unreadCount ? '+' + unreadCount : '0'}
              </div>
            </div>
            <div className="ctrl-wrapper">
              <Link to={`/mypage${window.location.search}`}>
                <span className="setting-icon">
                  <img src="/img/icon-setting.svg"></img>
                </span>
              </Link>
            </div>
          </div>
          <div className="lillo-iframe-profile">
            {me?.isActive
              ? `${me.subscriberName || ''}님 반가워요✋ ${
                  brand.brandName || ''
                }의 소식을 알려드릴게요`
              : '반가워요✋' +
                ` ${brand.brandName || ''}의 소식을 알려드릴게요`}
          </div>
        </section>
        {!itemsLoading ? (
          items?.length > 0 ? (
            <section className="lillo-iframe-items">
              {list
                ? list.map((dataset: any, datasetIdx: number) => (
                    <div
                      className="item-list-by-date"
                      key={`list-by-date${datasetIdx}`}
                    >
                      <div className="date-text">{dataset.display || ''}</div>
                      <ul className="item-list">
                        {dataset.dateValues &&
                          dataset.dateValues.length > 0 &&
                          dataset.dateValues.map((item: any, iIdx: number) => (
                            <li
                              key={`item-${iIdx}`}
                              data-aos="fade-up"
                              data-aos-offset="-30"
                              data-aos-duration={5000}
                              data-aos-delay={50 * iIdx}
                              ata-aos-anchor-placement="top-bottom"
                            >
                              <a
                                onClick={() =>
                                  doRedirect(item.campaignId, item.link)
                                }
                              >
                                <div className="item-title">
                                  {item.isRead === 'unread' && (
                                    <div className="noti-read"></div>
                                  )}
                                  <b>{item.title || ''}</b>
                                  <span className="text-time">
                                    {item.display || ''}
                                  </span>
                                </div>
                                <div className="item-desc">
                                  {item.content || ''}
                                </div>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))
                : '새로운 알림이 없습니다.'}
            </section>
          ) : (
            <section className="lillo-iframe-no-items">
              <div className="img-area">
                <img src="/img/icon-empty-box.png" />
              </div>
              <div className="no-item-text">아직 알림이 없습니다.</div>
            </section>
          )
        ) : (
          <section className="lillo-iframe-no-items">
            <LoadingRound />
          </section>
        )}
      </div>
    </BoxWrapper>
  );
};

export default NotifiedBox;
