import axios from 'axios';

// axios.defaults.withCredentials = true;

// const localToken = localStorage.getItem('browserToken');

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const browserToken = urlParams.get('browserToken');
const clientKey = urlParams.get('clientKey');

if (browserToken) {
  axios.defaults.headers.common = { Authorization: `Bearer ${browserToken}` };
}

const baseURL = process.env.REACT_APP_API_URI;

const defaultClient = axios.create({
  baseURL,
  params: {
    clientKey: clientKey,
  },
  // withCredentials: true,
});

export default defaultClient;
