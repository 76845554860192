import axios from './defaultClient';

const nameSpace = '/v1/notifications';

export const getNotificationPosts = async (args: any) => {
  return await axios.get(nameSpace + '/', {
    params: {
      paged: args.paged,
      itemsPerPage: args.itemsPerPage,
      type: args.type,
    },
  });
};

export const readNotificationPost = async (args: any) => {
  return await axios.patch(nameSpace + '/read/' + args.campaignId, {
    args,
  });
};
