import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import packageJson from '../../../package.json';

const Wrapper = styled.div`
  margin: 0;
  padding: 0;

  .lillo-iframe-bottom-mark {
    max-width: 500px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
  .lillo-iframe-bottom-mark .white-gradient {
    /* width: 100%; */
    transition: 0.5s;
    height: 120px;
    background: linear-gradient(
      180deg,
      rgba(247, 247, 247, 0) 0%,
      #f7f7f7 100%
    );
    &.disable {
      opacity: 0;
      visibility: hidden;
    }
  }
  .lillo-iframe-bottom-mark .lillo-mark {
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    gap: 4px;
  }
  .lillo-iframe-bottom-mark .lillo-mark span {
    color: #999;
    font-size: 11px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.3px;
    margin-bottom: 2px;
  }
  .lillo-iframe-bottom-mark .lillo-mark .img-box {
    width: 12px;
    height: 12px;
    background-image: url(/img/lillo-logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    border-bottom: 1px solid #e9ecef;
    font-size: 12px;
    svg {
      position: relative;
      bottom: 1px;
    }
  }
  .mail {
    cursor: pointer;
  }
  .inner {
    min-height: calc(100vh - 56px);
  }
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: #222;
  margin-bottom: 15px;
  svg {
    position: relative;
    bottom: -2px;
    width: 10px;
    height: 17px;
    margin-right: 12px;
  }
`;

const Items = styled.div`
  ul {
    border-bottom: 1px solid #e9ecef;
    padding-bottom: 15px;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    letter-spacing: -0.03em;
    color: #000000;

    span {
      display: block;
      font-size: 13px;
      line-height: 160%;
      font-weight: 400;
      letter-spacing: -0.03em;
      color: #6c757d;
      margin-top: 5px;
    }

    .active {
      background: #48b534;
      :after {
        background-color: #fff;
        left: calc(100% - 3px);
        transform: translate(-100%, -50%);
      }
    }
  }
`;

const ToggleSwitch = styled.div`
  position: relative;
  flex: 0 0 33px;
  height: 22px;
  background: #ced4da;
  border-radius: 20px;
  transition: 0.28s;
  cursor: pointer;
  :after {
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    left: 3px;
    transform: translate(0, -50%);
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
    transition: 0.28s;
  }
`;

const Footer = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: -0.03em;
  color: #949ca5;
  text-align: center;
  padding: 30px 0 10px 0;
  img {
    position: relative;
    top: -1px;
    width: 16px;
    margin-right: 4px;
  }
`;

const Mypage = ({ setState, me, contact, brandData }: any) => {
  return (
    <Wrapper>
      <div className="inner" style={{ padding: '0 20px' }}>
        <Title>
          <Link to={`/notification${window.location.search}`}>
            {/* <img src="/img/vector50.svg" /> */}
            <svg
              width="12"
              height="18"
              viewBox="0 0 12 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3809 0.880005L1.63086 9.005L10.3809 17.13"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
            설정
          </Link>
        </Title>
        <Items>
          <ul>
            <li>
              <div>앱푸시</div>
              <ToggleSwitch
                onClick={() => setState('agreePushNoti')}
                className={me?.agreePushNoti ? 'active' : ''}
              />
            </li>
            {me?.agreePushNoti && (
              <>
                <li>
                  <div>
                    이벤트 혜택
                    <span>세일, 프로모션 등 각종 이벤트 알림</span>
                  </div>
                  <ToggleSwitch
                    onClick={() => setState('agreeEventNoti')}
                    className={me?.agreeEventNoti ? 'active' : ''}
                  />
                </li>
                <li>
                  <div>
                    쿠폰 포인트 안내
                    <span>쿠폰·포인트 적립 및 소멸 안내</span>
                  </div>
                  <ToggleSwitch
                    onClick={() => setState('agreePromotionNoti')}
                    className={me?.agreePromotionNoti ? 'active' : ''}
                  />
                </li>
                {/* <li>
            <div>
              리뷰 쓰기
              <span>리뷰 쓰기 알림</span>
            </div>
            <ToggleSwitch
              onClick={() => setState('c')}
              className={me?.c ? 'active' : ''}
            />
          </li>
          <li>
            <div>
              재입고 알림
              <span>재입고 신청한 상품의 입고 알림</span>
            </div>
            <ToggleSwitch
              onClick={() => setState('d')}
              className={me?.d ? 'active' : ''}
            />
          </li>
          <li>
            <div>
              야간 혜택 알림
              <span>
                오후 9시 ~ 오전 8시에
                <br />
                야간 프로모션 혜택을 받습니다.
              </span>
            </div>
            <ToggleSwitch
              onClick={() => setState('e')}
              className={me?.e ? 'active' : ''}
            />
          </li> */}
              </>
            )}
          </ul>
        </Items>
        <div className="item mail" onClick={contact}>
          <p>🚨 앱 문의·신고</p>
          <span>
            {/* <img src="/img/vector51.svg" alt="" /> */}
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.16064 11.1792L6.34609 5.99372L1.16064 0.80827"
                stroke="#6C757D"
              />
            </svg>
          </span>
        </div>
        <div className="item">
          <p>버전 정보</p>
          <span>
            {/* ver {brandData?.appVersion ? brandData?.appVersion : '0.0.1'} */}
            ver {packageJson.version ? packageJson.version : '0.0.1'}
          </span>
        </div>
      </div>
      <section
        className="lillo-iframe-bottom-mark"
        onClick={() => {
          window.open('https://lillo.co.kr');
        }}
      >
        <div className={'white-gradient'}></div>
        <div className="lillo-mark">
          <div className="img-box"></div>
          <span>릴로 알림함 이용중</span>
        </div>
      </section>
      {/* <Footer
        onClick={() => {
          window.open('https://lillo.co.kr');
        }}
      >
        <img src="/img/logo_icon.png" alt="" />
        릴로 알림함 이용중
      </Footer> */}
    </Wrapper>
  );
};

export default Mypage;
