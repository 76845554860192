import React from 'react';
import { LoadingRoundStyle } from './styled';

const LoadingRound = () => {
    return (
        <LoadingRoundStyle>
            <div class="loading circle">
                <div />
                <ul className="s-cricle">
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </LoadingRoundStyle>
    );
};

export default LoadingRound;
