import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */

  .inner {
    /* min-height: 400px; */
  }
`;

export const Profile = styled.div`
  position: relative;
  font-size: 27px;
  line-height: 160%;
  font-weight: 700;
  letter-spacing: -0.03em;
  color: #adb5bd;
  margin-bottom: 50px;
  strong {
    color: #000;
  }
  span {
    display: block;
    font-size: 15px;
    line-height: 160%;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: #6c757d;
    margin-top: 5px;
  }
  img {
    position: absolute;
    right: 3px;
    top: 10px;
    width: 17px;
    height: auto;
    cursor: pointer;
  }
`;

export const Tab = styled.ul`
  display: inline-block;
  li {
    float: left;
    margin-right: 30px;
    cursor: pointer;
    :last-child {
      margin-right: 0;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.03em;
      color: #6c757d;
    }
  }
  li.active {
    span {
      font-weight: 700;
      color: #fb4700;
    }
  }
`;

export const Itmes = styled.ul`
  margin: 30px 0;
  min-height: calc(100vh - 404px);
  li {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e9ecef;
  }
  .top-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    strong {
      position: relative;
      padding-left: 15px;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.03em;
      color: #000000;
      :after {
        display: block;
        position: absolute;
        content: '';
        top: 8px;
        left: 0;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #f3520d;
      }
    }
    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.03em;
      color: #6c757d;
      flex: 0 0 auto;
    }
  }
  .null-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.03em;
    color: #6c757d;
    text-align: center;
    margin-top: 80px;
  }
  .bottom-item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* align-items: flex-end; */
    p {
      min-height: 20px;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.03em;
      color: #343a40;
      margin-bottom: 10px;
    }
    .category {
      width: fit-content;
      height: 26px;
      border-radius: 40px;
      font-size: 11px;
      line-height: 26px;
      font-weight: 400;
      letter-spacing: -0.03em;
      color: #6c757d;
      border: 1px solid #949ca5;
      padding: 0 12px;
      img {
        position: relative;
        bottom: 1px;
        width: 13px;
        margin-right: 6px;
      }
    }
    .thumbnail {
      flex: 0 0 65px;
      height: 65px;
      border-radius: 5px;
      background-image: url('');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
`;

export const Footer = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.03em;
  color: #949ca5;
  text-align: center;
  margin: 80px 0px 10px 0;
  img {
    position: relative;
    top: -1px;
    width: 16px;
    margin-right: 4px;
  }
`;

export const BoxWrapper = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: content-box; /* So the width will be 100% + 17px */
  .lillo-iframe-bottom-mark {
    max-width: 500px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
  .lillo-iframe-bottom-mark .white-gradient {
    /* width: 100%; */
    /* transition: 0.5s; */
    height: 120px;
    background: linear-gradient(
      180deg,
      rgba(247, 247, 247, 0) 0%,
      #f7f7f7 100%
    );
    &.disable {
      opacity: 0;
      visibility: hidden;
    }
  }
  .lillo-iframe-bottom-mark .lillo-mark {
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    gap: 4px;
  }
  .lillo-iframe-bottom-mark .lillo-mark span {
    color: #999;
    font-size: 11px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.3px;
    margin-bottom: 2px;
  }
  .lillo-iframe-bottom-mark .lillo-mark .img-box {
    width: 12px;
    height: 12px;
    background-image: url(/img/lillo-logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .lillo-iframe-no-items {
    cursor: pointer;

    background-color: #f7f7f8;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    gap: 8px;
    .img-area {
      img {
        width: 32px;
        height: 32px;
      }
    }
    .no-item-text {
      color: #adb5bd;
      text-align: center;
      font-size: 12px;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: -0.36px;
    }
  }
  .noti-box-close-icon-wrapper {
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 10px;
      height: 10px;
    }
  }

  .lillo-iframe-box-inner {
    .lillo-noti-box-head {
      position: fixed;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 500px;
      background: #fff;
      z-index: 1;
    }
    .lillo-iframe-noti-box-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 23px;
      padding-left: 24px;
      padding-bottom: 4px;
      .main-title {
        height: 28px;
        display: flex;
        align-items: center;

        gap: 6px;
        .main-icon-wrapper {
          width: 24px;
          height: 24px;

          border-radius: 10px;
          border: 1px solid #eee;
        }
        .main-title-text {
          height: 18px;
          color: #000;
          font-size: 16px;
          font-family: Noto Sans KR;
          font-style: normal;
          /* line-height: 1.6; */
          line-height: 16px;
          letter-spacing: -0.48px;
          font-weight: 700;
          .brand-name {
            opacity: 0.6;
          }
        }
        .main-item-count {
          height: 18px;
          padding: 0px 6px;
          flex-direction: column;
          gap: 6px;
          border-radius: 40.909px;
          background: #fb4700;

          color: #fff;
          font-size: 11px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 700;
          line-height: 1.6;
          &.all-read {
            background: #ccc;
          }
        }
      }
      .ctrl-wrapper {
        display: flex;
        align-items: center;
        a .setting-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 21px;
          height: 21px;
          margin-right: 29px;
          margin-top: -6px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .lillo-iframe-profile {
      padding-right: 23px;
      padding-left: 24px;
      padding-bottom: 16px;

      color: #999;
      font-size: 13px;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      text-transform: uppercase;
    }

    .lillo-iframe-items {
      background-color: #f7f7f8;
      padding: 70px 20px 45px 20px;
      min-height: 100vh;
      .item-list-by-date {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 20px;
        .date-text {
          padding-left: 4px;
          color: #666;
          font-size: 12px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          text-transform: uppercase;
        }
        .item-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          li a {
            display: flex;
            padding: 14px 20px 16px 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;

            cursor: pointer;
            border-radius: 18px;
            border: 1px solid #eee;
            background: rgba(255, 255, 255, 0.98);
            box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(2px);
            transition: 0.3s;
            :hover {
              transform: translateY(-3px);
            }
            .item-title {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;

              b {
                max-width: calc(100% - 65px);
                word-break: break-all;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                color: #000;
              }

              font-size: 13px;
              font-family: Noto Sans KR;
              font-style: normal;
              line-height: 160%;
              letter-spacing: -0.39px;
              .noti-read {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #fb4700;
              }
              .text-time {
                color: #999;
                font-size: 11px;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.33px;
              }
            }
            .item-desc {
              color: #666;
              font-size: 12px;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
              letter-spacing: -0.36px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
`;
