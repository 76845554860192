import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { validate } from '../../../service/auth';
import { getBrandPost } from '../../../service/brand';
import { getMe } from '../../../service/me';
import { atomBrandId, brandFields, userFields } from '../../../store';
import { BoxWrapper, Wrapper } from './styled';

const Layout = ({ children, type }: any) => {
    const setBrandData = useSetRecoilState(brandFields);
    const setUserData = useSetRecoilState(userFields);
    const [searchParams] = useSearchParams();
    const [brandId, setBrandId] = useRecoilState(atomBrandId);
    const token: any = searchParams.get('browserToken');

    useEffect(() => {
        validate()
            .then((res: any) => {
                if (res.status === 200) {
                    setBrandId(res.data.brandId);
                }
                console.log(res);
            })
            .catch((err: any) => {
                console.log(err);
            });
    }, [window.location]);

    useEffect(() => {
        if (token && brandId) {
            getBrandPost({ brandId: brandId })
                .then((res) => {
                    // console.log(res.data.brandInfo);
                    setBrandData(res.data.brandInfo);
                })
                .catch((err) => {
                    setBrandData({});
                    console.log(err);
                });
            getMe({})
                .then((res) => {
                    if (res.status === 200) {
                        setUserData(res.data.data);
                    } else {
                        // localStorage.clear();
                    }
                })
                .catch((err) => {
                    // localStorage.clear();
                    console.log(err);
                });
        } else {
            // localStorage.clear();
        }
    }, [brandId]);

    return type === 'BOX' ? <BoxWrapper>{children}</BoxWrapper> : <Wrapper>{children}</Wrapper>;
};

export default Layout;
