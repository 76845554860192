import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Layout from '../components/common/layout';
import Mypage from '../components/mypage/Mypage';
import { updateSetting } from '../service/me';
import { brandFields, userFields } from '../store';

const MypageContainer = () => {
  const [me, setMe] = useRecoilState(userFields);
  const brandData = useRecoilValue(brandFields);

  const setState = (args: any) => {
    let obj: any = { ...me, [args]: !me[args] };
    console.log('obj', obj);
    setMe(obj);
    update(obj);
  };

  const update = (obj: any) => {
    updateSetting(obj)
      .then((res: any) => {
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const contact = () => {
    window.open('mailto:hello@lillo.co.kr');
  };

  return (
    <Layout type="BOX">
      <Mypage
        setState={setState}
        me={me}
        contact={contact}
        brandData={brandData}
      />
    </Layout>
  );
};

export default MypageContainer;
