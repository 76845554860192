import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px;
    overflow: hidden;

    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
`;

export const BoxWrapper = styled.div`
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
`;
