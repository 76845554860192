import React, { useEffect, useState } from 'react';
import Layout from '../components/common/layout';
import Notification from '../components/notification/Notification';
import {
  getNotificationPosts,
  readNotificationPost,
} from '../service/notification';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { atomBrandId, brandFields, userFields } from '../store';
import NotifiedBox from '../components/notification/NotifiedBox';
import moment from 'moment';

type dateText = '오늘' | '어제' | '지난 알림';
const NotificationContainer = () => {
  const [tab, setTab] = useState('');
  const [items, setItems] = useState<Array<Object> | any>([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get('browserToken');
  // const brandId = searchParams.get('brandId');
  const brandId = useRecoilValue(atomBrandId);
  const brand = useRecoilValue(brandFields);
  const me = useRecoilValue(userFields);
  const [paged, setPaged] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [list, setList] = useState<any>([]);
  const [unreadCount, setUnreadCount] = useState<number | ''>('');

  const fromNowTime = (time: string) => {
    let resultData = '';
    if (time) {
      const nowFormat = moment();
      const tFormat = moment(time);
      const formatDisplay = tFormat.format('YYYY.MM.DD');
      const fromMinute = moment(nowFormat).diff(tFormat, 'minute');
      const fromHours = moment(nowFormat).diff(tFormat, 'hours');
      const fromDays = moment(nowFormat).diff(tFormat, 'days');
      if (time) {
        if (fromMinute < 60) resultData = `${Math.floor(fromMinute)}분 전`;
        else if (fromHours < 48) resultData = `${Math.floor(fromHours)}시간 전`;
        else resultData = formatDisplay;
      }
    }
    return resultData;
  };
  const findToday = (time: string) => {
    const nowForm = moment().format('YYYY-MM-DD');
    const fromDays = moment(nowForm).diff(time, 'days');
    let isTodayAndYesterday = -1;
    if (nowForm === time) {
      isTodayAndYesterday = 0;
    } else if (fromDays > 0) isTodayAndYesterday = fromDays;

    return isTodayAndYesterday;
  };

  const replaceItem = () => {
    let result: any = [];
    // 어제,오늘 있는지 확인
    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        const thisDate = items[i].date?.slice(0, 10) || '';
        let displayKey: dateText = '지난 알림';
        const display = fromNowTime(items[i].date) || '';

        const findDisplayKey = findToday(thisDate);
        if (findDisplayKey === 0) displayKey = '오늘';
        else if (findDisplayKey === 1) displayKey = '어제';
        else displayKey = '지난 알림';

        const isDateKey = result.findIndex(
          (f: any) => f.display === displayKey
        );

        if (i === 0 || isDateKey === -1) {
          result = [
            ...result,
            {
              display: displayKey,
              dateValues: [{ ...items[i], display }],
            },
          ];
        } else result[isDateKey].dateValues.push({ ...items[i], display });
      }
    } else result = items || [];
    setList(result);
  };

  const tabHandler = (args: any) => {
    if (args !== tab) {
      setItems([]);
      setPaged(1);
      setTab(args);
    }
  };

  useEffect(() => {
    if (token && brandId) {
      setItemsLoading(true);
      getNotificationPosts({
        type: tab,
        paged: paged,
        itemsPerPage: itemsPerPage,
      })
        .then((res: any) => {
          console.log(res.data.items);
          if (res.data.items?.length === 0) {
            setItems(null);
          } else {
            setItems(res.data.items);
          }
          if (!tab) {
            let unreadItems = res.data.items.filter(
              (item: any) => item.isRead == 'unread'
            );
            window.parent.postMessage(
              {
                lastNotiId: res.data.items[0]?.campaignId,
                unreadCount: unreadItems.length,
              },
              '*'
            );
            setUnreadCount(unreadItems.length);
            // console.log({ lastNotiId: res.data.items[0]?.campaignId, unreadCount: unreadItems.length });
          }
          setItemsLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setItemsLoading(false);
          setItems([]);
        });
    }
    console.log('api 호출');
  }, [token, brandId, tab, paged]);

  const doRedirect = (campaignId: number, redirectUrl: string) => {
    if (!campaignId) return;

    if (!redirectUrl) return;

    readNotificationPost({
      campaignId,
    })
      .then((res) => {
        if (res.status == 200) {
          console.log(res);
          window.parent.location.href = redirectUrl;
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (items) replaceItem();
  }, [items]);

  return (
    <>
      <Layout type={'BOX'}>
        <NotifiedBox
          brand={brand}
          me={me}
          setTab={setTab}
          items={items}
          itemsLoading={itemsLoading}
          list={list}
          doRedirect={doRedirect}
          unreadCount={unreadCount}
        />
      </Layout>

      {/* <Layout>
        <Notification
          brand={brand}
          me={me}
          tab={tab}
          tabHandler={tabHandler}
          setTab={setTab}
          items={items}
        />
      </Layout> */}
    </>
  );
};

export default NotificationContainer;
